import { Box, Center, Text } from "@chakra-ui/react";

const SelectBranchInfo = ({message}) => {
    message = message || "Please select a branch to get started";
  return (
    <Center>
      <Box
        outline="1px dotted"
        mt={10}
        outlineColor="gray.300"
        p={4}
        h="70px"
        borderRadius={10}
        w={{ base: "90%", md: "50%" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Center>
          <Text color="gray.500">{message}</Text>
        </Center>
      </Box>
    </Center>
  );
};

export default SelectBranchInfo;
