import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { updateDrawer } from "../../store/actions/miscAction";
import { getPaymentArchiveRecord } from "../../store/actions/paymentsActions";

const PaymentsTable = ({ payments }) => {
  const dispatch = useDispatch();
  const openDrawer = (payment) => {
    dispatch(getPaymentArchiveRecord(payment.id));
    const drawer = {
      title: "Payment Details",
      isOpen: 1,
      content: { component: "PaymentsCard" },
    };
    dispatch(updateDrawer(drawer));
  };

  return (
    <Box>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Payment Created</Th>
            <Th>Response Text</Th>
            <Th>Paycon Status</Th>
            <Th>Reference</Th>
          </Tr>
        </Thead>
        <Tbody>
          {payments.map((payment, i) => {
            const { created, id, response_text } = payment;
            const status = 'Click to view';
            return (
              <>
                <Tr
                  key={id}
                  onClick={() => openDrawer(payment)}
                  cursor={"pointer"}
                >
                  <Td>{i + 1}</Td>
                  <Td>
                    {" "}
                    {dayjs.unix(created).format("YYYY-MM-DD HH:mm:ss")}
                  </Td>
                  <Td>{response_text}</Td>
                  <Td>{status}</Td>
                  <Td>
                    {id}
                  </Td>
                </Tr>
              </>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PaymentsTable;
