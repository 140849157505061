import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Text } from "@chakra-ui/react";
import { updateBranchId } from "../../store/actions/defaultAction";
import Select from "./../averly/Select";

const BranchSelection = () => {
  const dispatch = useDispatch();
  const { branches, organizationId, branchId } = useSelector(
    (store) => store.defaultReducer
  );
  const orgBranches = branches.filter(
    (branch) =>
      branch.organizationId === organizationId 
    && Boolean(branch.ssStatus) === Boolean(true)
  );

  const onSelect = (branchId) => {
    dispatch(updateBranchId({ branchId, organizationId }));
  };

  return (
    <Box>
      <Select
        options={orgBranches}
        label={"Select Branch"}
        placeholder="All Branches"
        value={branchId}
        onChange={onSelect}
      />
    </Box>
  );
};

export default BranchSelection;
