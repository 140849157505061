import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Center,
  Divider,
  HStack,
  Button,
  Select,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import "../assets/css/Filter.css";
import { setDateRange } from "../store/actions/defaultAction";
import "react-toastify/dist/ReactToastify.css";
import BranchSelection from "./filterForm/BranchSelection";
import EmployerSelection from "./filterForm/EmployerSelection";
import URLSelection from "./filterForm/URLSelection";
import ReferenceNumberInput from "./filterForm/ReferenceNumberInput";
import { getLogs } from "../store/actions/defaultAction";
import _ from "lodash";
import { formatNumber } from "../store/helpers";
import { fetchPayments } from "../store/actions/paymentsActions";

function FilterForm({ isPayments = 0 }) {
  const { logs, dateRange, organizationId, branchId, employerNumber, url } =
    useSelector((state) => state.defaultReducer);
    const { paymentsData, referenceNumber } = useSelector(state=>state.paymentsReducer)
  const logsClone = _.cloneDeep(logs);
  const [showBranchAndDateSelection, setShowBranchAndDateSelection] =
    useState(false);

  const [showSearchButton, setShowSearchButton] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowBranchAndDateSelection(!!organizationId);
    setShowSearchButton(!!organizationId);
  }, [organizationId]);

  const handleDateRangeUpdate = (e) => {
    const start = dayjs(e[0]).unix();
    const end = dayjs(e[1]).unix();

    dispatch(setDateRange([start, end]));
  };

  // trigger deployment

  return (
    <Box
      className="filterForm"
      p={5}
      fontSize={14}
      borderWidth={1}
      borderColor="#ececec"
      borderRadius={5}
      mt={10}
    >
      <Text fontSize="lg">Filter logs</Text>
      <Center height="20px">
        <Divider />
      </Center>
      <HStack spacing="24px" mt={5}>
        {!isPayments ? (
          <Box flexGrow={1}>
            <URLSelection />
          </Box>
        ) : null}
        <Box flexGrow={1}>
          <BranchSelection />
        </Box>

          <Box flexGrow={1}>
            <EmployerSelection />
          </Box>

        {isPayments ? (
          <Box flexGrow={1}>
            <ReferenceNumberInput />
          </Box>
        ) : null}
      </HStack>

      <HStack spacing="24px" mt={5}>
        {showBranchAndDateSelection && (
          <>
            <Box>
              <Text fontSize={14}>Date range</Text>
              <DateRangePicker
                clearIcon={null}
                format="dd-MM-y"
                onChange={handleDateRangeUpdate}
                value={[
                  new Date(dayjs.unix(dateRange[0]).format("YYYY-MM-DD")),
                  new Date(dayjs.unix(dateRange[1]).format("YYYY-MM-DD")),
                ]}
              />
            </Box>
          </>
        )}
      </HStack>
      {showSearchButton && (
        <Button
          mt={5}
          onClick={() => {
            if (isPayments) {
              dispatch(fetchPayments(dateRange, referenceNumber, branchId));
              return;
            } else {
              dispatch(
                getLogs(
                  dateRange,
                  branchId || null,
                  employerNumber || null,
                  url || null
                )
              );
            }
          }}
        >
          Search
        </Button>
      )}
      <Divider m="10px 0" />
      <Box>{isPayments ? `Total Payments: ${formatNumber(branchId.length> 0 ? paymentsData.filter((a)=>a.branchId === branchId).length : paymentsData?.length)}` : `Total Logs: ${formatNumber(logsClone?.length)}`}</Box>
    </Box>
  );
}

export default FilterForm;
