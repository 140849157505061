import { Box } from "@chakra-ui/react";
import PaymentsTable from "./PaymentsTable";
import { useSelector } from "react-redux";
import _ from "lodash";
import SelectBranchInfo from "../misc/SelectBranchInfo";

const PaymentsComponent = () => {
  const { paymentsData } = useSelector((state) => state.paymentsReducer);
  const { branchId, employerNumber } = useSelector(
    (state) => state.defaultReducer
  );
  const paymentsClone = _.cloneDeep(paymentsData);
  //filter payments by branchId if it exists
  let filteredPayments =
    branchId.length > 0
      ? paymentsClone.filter((a) => a.branchId === branchId)
      : paymentsClone;

  // filter payments by employerNumber if it exists
  filteredPayments =
    employerNumber.length > 0
      ? filteredPayments.filter((a) => a.ssc_number === employerNumber)
      : filteredPayments;

  return (
    <Box>
      {filteredPayments.filter((a)=>a.branchId === branchId)?.length === 0 ? <SelectBranchInfo message={'No data for current selection, Update Filters, Select a branch and Hit Search'}/> : <PaymentsTable payments={filteredPayments} />}
    </Box>
  );
};

export default PaymentsComponent;
